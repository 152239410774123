* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  word-wrap: break-word;
}

html,
body {
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
